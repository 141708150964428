import React from "react"
import { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { ChainId, DAppProvider, useEtherBalance, useEthers, useContractFunction, useTokenBalance, useContractCall, useContractCalls, ERC20Interface } from '@usedapp/core'
import { formatEther } from '@ethersproject/units'
import { utils } from 'ethers'
import { Contract } from '@ethersproject/contracts'
import { getContractData, getContractAddress, getVibesAddress, getSillAddress, getSillContractData, useSimpleContractCall, useArglessContractCall } from "../utils/contract.js"

export default function StakeDapp(props) {

  const StakingLoadingIndicator = props => {
    return (
      <div style={{
          marginTop: "15px",
          width: "100%",
          height: "25",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <Loader type="BallTriangle" color="#44615a" height="25" width="25" />
      </div>
    );  
  }
  const mmImagesBaseUrl = process.env.GATSBY_IMAGES_URL

  // Wallet Activation
  const { activateBrowserWallet, account, library } = useEthers()

  // Moody Monsteras Contract
  const mmInterface = new utils.Interface(getContractData().abi)
  const mmContractAddress = getContractAddress()
  const mmContract = new Contract(mmContractAddress, mmInterface)

  // The Window Sill Contract
  const sillInterface = new utils.Interface(getSillContractData().abi)
  const sillContractAddress = getSillAddress()
  const sillContract = new Contract(sillContractAddress, sillInterface)

  // $VIBES Contract
  const vibesContractAddress = getVibesAddress()
  const vibesContract = new Contract(vibesContractAddress, ERC20Interface)

  // State variables that need to be tracked
  const [lastTerminalWriteState, setLastTerminalWriteState] = useState(null);
  const [connectDelaying, setConnectDelaying] = useState(true);
  const [allTokenIdCalls, setAllTokenIdCalls] = useState([]);
  const [allTokenIdNameCalls, setAllTokenIdNameCalls] = useState([]);
  const [allStakedTokenIdCalls, setAllStakedTokenIdCalls] = useState([]);
  const [tokenIdToNameMap, setTokenIdToNameMap] = useState({});
  const [tokenIdToStakedMap, setTokenIdToStakedMap] = useState({});
  const [allStakedTokenIds, setAllStakedTokenIds] = useState([]);
  const [allSillTokenIdCalls, setAllSillTokenIdCalls] = useState([]);
  const [allOwnersOfStakedTokenIdCalls, setAllOwnersOfStakedTokenIdCalls] = useState([]);
  const [bannerOpen, setBannerOpen] = useState(0);
  const [bannerContent, setBannerContent] = useState(0);
  const [stakingLoadingMap, setStakingLoadingMap] = useState({});

  // Call: is Sill approved for Monstera transfers
  const sillIsApproved = useSimpleContractCall(mmContract, 'isApprovedForAll', [account, sillContractAddress])
  // Call: the amount of VIBES available to claim
  const stakerInfoClaimable = useSimpleContractCall(sillContract, 'getReward', account)
  // Call: info on the staker/user 
  const [stakerInfoLastAction, stakerInfoBalance, stakerInfoAccumulated] = useContractCall(account != null && {
    abi: sillContract.interface, 
    address: sillContract.address, 
    method: 'stakerInfos', 
    args: account && [account] 
  }) ?? []
  // Call: Owned Monstera balance
  const walletTokenBalance = useTokenBalance(mmContractAddress, account)
  // Call: Owned Monstera data
  const walletTokensResults = useContractCalls(allTokenIdCalls)
  // Call: Owned Monstera Names
  const walletTokensNameResults = useContractCalls(allTokenIdNameCalls)
  // Call: VIBES balance
  const walletVibesBalance = useTokenBalance(vibesContractAddress, account)
  // Call: Owned Monstera staked on the Sill
  const walletStakedTokensResults = useContractCalls(allStakedTokenIdCalls)
  // Call: Monstera balance on the Window Sill
  const sillTokenBalance = useTokenBalance(mmContractAddress, sillContractAddress)
  // Call: All the Monstera ids held on the Sill
  const sillTokensResults = useContractCalls(allSillTokenIdCalls)
  // Call: All the Owner ids of Monsteras held on the Sill
  const sillOwnersOfStakedTokensResults = useContractCalls(allOwnersOfStakedTokenIdCalls)

  // Transaction: approve the Sill for Monstera transfers
  const { state:approveState, send:approveSend } = useContractFunction(mmContract, 'setApprovalForAll', { transactionName: 'Approve For All' })
  // Transaction: stake Monstera(s) on the Window Sill
  const { state:stakeState, send:stakeSend } = useContractFunction(sillContract, 'stake', { transactionName: 'Stake' })
  // Transaction: unstake Monstera(s) on the Window Sill
  const { state:unstakeState, send:unstakeSend } = useContractFunction(sillContract, 'unstake', { transactionName: 'Unstake' })
  // Transaction: claim Vibes from the Window Sill
  const { state:claimState, send:claimSend } = useContractFunction(sillContract, 'claimRewards', { transactionName: 'Claim' })

  // Creates a small delay before showing some components in order to give the wallet time to connect
  setTimeout(() => {
    setConnectDelaying(false)
  }, 300);


  /*
  * FUNCTIONS
  */
  
  // Called when a wallet can't connect
  const onWalletActivationError = (error) => {
    console.log(error.message)
    setBannerContent(error.message)
    setBannerOpen(true)
  }

  // Prepare to attempt to approve Monstera transfers
  const attemptToApproveForAll = () => {
    if (approveState && approveState.status == "Mining") {
      setBannerContent("Approving Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Approving!!!")
    setLastTerminalWriteState("Approving...")
    approveSend(sillContractAddress, true)
  }

  // Prepare to attempt to claim vibes
  const attemptToClaimVibes = () => {
    if (claimState && claimState.status == "Mining") {
      setBannerContent("Claiming Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Claiming!!!")
    setLastTerminalWriteState("Claiming...")
    claimSend()
  }

  // Prepare to stake
  const attemptToStake = (bigIds) => {
    if (stakeState && stakeState.status == "Mining") {
      setBannerContent("Staking Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    setLastTerminalWriteState("Staking...")
    var ids = bigIds.map(bigId => utils.formatUnits(bigId[0], 0));
    console.log("Staking!!! "+ ids)
    var newStakingLoadingMap = stakingLoadingMap
    ids.forEach(id => {newStakingLoadingMap[id] = true});
    setStakingLoadingMap(newStakingLoadingMap)
    stakeSend(ids)
  }

  // Prepare to unstake
  const attemptToUnstake = (bigIds) => {
    if (unstakeState && unstakeState.status == "Mining") {
      setBannerContent("Unstaking Already In Progress!")
      setBannerOpen(true)
      return
    }
    setBannerOpen(false)
    console.log("Unstaking!!! "+ ids)
    setLastTerminalWriteState("Unstaking...")
    var ids = bigIds.map(bigId => utils.formatUnits(bigId[0], 0));
    console.log("Staking!!! "+ ids)
    var newStakingLoadingMap = stakingLoadingMap
    ids.forEach(id => {newStakingLoadingMap[id] = true});
    setStakingLoadingMap(newStakingLoadingMap)
    unstakeSend(ids)
  }

  // Build array of calls to get each of a owners Monstera token ids
  const buildWalletTokenCalls = (tokensInWallet) => {
    var allTokenIdCalls = []
    for (let i = 0; i < tokensInWallet; i++) { 
      allTokenIdCalls.push(
        {
          abi: mmInterface, // ABI interface of the called contract
          address: mmContractAddress, // On-chain address of the deployed contract
          method: 'tokenOfOwnerByIndex', // Method to be called
          args: [account, i]
        }
      )
    }
    
    return allTokenIdCalls
  }

  // Build array of calls to get each Monstera id's name
  const buildWalletTokenNameCalls = (walletTokens) => {
    var allTokenIdNameCalls = []
    for (let i = 0; i < walletTokens.length; i++) { 
      if (walletTokens[i] !== undefined) {
        allTokenIdNameCalls.push(
          {
            abi: mmInterface, // ABI interface of the called contract
            address: mmContractAddress, // On-chain address of the deployed contract
            method: 'viewName', // Method to be called
            args: [walletTokens[i][0]]
          }
        )
      }
    }
    
    return allTokenIdNameCalls
  }

  // Build calls to get all the owners of staked Monsteras
  const buildOwnersOfStakedTokenIdCalls = (sillTokens) => {
    var allOwnersOfStakedTokenIdCalls = []
    for (let i = 0; i < sillTokens.length; i++) { 
      if (sillTokens[i] !== undefined) {
        allOwnersOfStakedTokenIdCalls.push(
          {
            abi: sillInterface, 
            address: sillContractAddress, 
            method: 'tokenOwners', 
            args: [sillTokens[i][0]]
          }
        )
      }
    }
    
    return allOwnersOfStakedTokenIdCalls
  }

  // Build calls to get ALL the Monstera ids staked on the Sill
  const buildSillTokenCalls = (tokensOnSill) => {
    var allSillTokenIdCalls = []
    for (let i = 0; i < tokensOnSill; i++) { 
      allSillTokenIdCalls.push(
        {
          abi: mmInterface, 
          address: mmContractAddress, 
          method: 'tokenOfOwnerByIndex', 
          args: [sillContractAddress, i]
        }
      )
    }
    
    return allSillTokenIdCalls
  }


  // Functions to deal with transaction states and tell the user
  const onWriteSuccess = (msg) => {
    if (lastTerminalWriteState != 'Success') {
      setBannerContent(msg)
      setBannerOpen(true)
      setLastTerminalWriteState('Success')
      setStakingLoadingMap({})
    }
  }
  const onWriteFail = (error) => {
    if (lastTerminalWriteState != error) {
      setBannerContent("Error: "+error)
      setBannerOpen(true)
      setLastTerminalWriteState(error)
      setStakingLoadingMap({})
    }
  }


  // Detect changes in tracked variables
  useEffect(() => {

    // WALLET

    if (walletTokenBalance && parseInt(utils.formatUnits(walletTokenBalance, 0)) > 0) {
      const tokenBalance = parseInt(utils.formatUnits(walletTokenBalance, 0))
      setAllTokenIdCalls(buildWalletTokenCalls(tokenBalance))
    }

    if (walletTokensResults) {
      setAllTokenIdNameCalls(buildWalletTokenNameCalls(walletTokensResults))
    }

    if (walletTokensNameResults) {
      let tokenIdToNameMap = {}
      for (let i = 0; i < walletTokensNameResults.length; i++) {
        if (walletTokensNameResults[i] !== undefined) {
          tokenIdToNameMap[walletTokensResults[i][0]] = walletTokensNameResults[i][0];
        }
      }
      setTokenIdToNameMap(tokenIdToNameMap)
    }

    // SILL

    if (sillTokenBalance && parseInt(utils.formatUnits(sillTokenBalance, 0)) > 0) {
      const tokenBalance = parseInt(utils.formatUnits(sillTokenBalance, 0))
      setAllSillTokenIdCalls(buildSillTokenCalls(tokenBalance))
    }

    if (sillTokensResults) {
      setAllOwnersOfStakedTokenIdCalls(buildOwnersOfStakedTokenIdCalls(sillTokensResults))
    }

    if (sillOwnersOfStakedTokensResults) {
      let stakedTokenIds = []
      for (let i = 0; i < sillTokensResults.length; i++) {
        if (account !== undefined && sillOwnersOfStakedTokensResults[i] == account) {
          stakedTokenIds.push(sillTokensResults[i])
        }
      }
      setAllStakedTokenIds(stakedTokenIds)
    }

    // STATES

    if (approveState && approveState.status == 'Success') {
      console.log(approveState.transaction)
      onWriteSuccess('Approval Complete!')
    }
    else if (approveState && (approveState.status == 'Fail' || approveState.status == 'Exception')) {
      console.log(approveState.transaction)
      onWriteFail(approveState.errorMessage || "Unknown Error")
    }

    if (claimState && claimState.status == 'Success') {
      console.log(claimState.transaction)
      onWriteSuccess('Claim Complete!')
    }
    else if (claimState && (claimState.status == 'Fail' || claimState.status == 'Exception')) {
      console.log(claimState.transaction)
      onWriteFail(claimState.errorMessage || "Unknown Error")
    }

    if (stakeState && stakeState.status == 'Success') {
      console.log(stakeState.transaction)
      onWriteSuccess('Staking Complete!\nReloading...')
      setTimeout(() => {window.location.reload()}, 500)
    }
    else if (stakeState && (stakeState.status == 'Fail' || stakeState.status == 'Exception')) {
      console.log(stakeState.transaction)
      onWriteFail(stakeState.errorMessage || "Unknown Error")
    }

    if (unstakeState && unstakeState.status == 'Success') {
      console.log(unstakeState.transaction)
      onWriteSuccess('Unstaking Complete!\nReloading...')
      setTimeout(() => {window.location.reload()}, 500)
    }
    else if (unstakeState && (unstakeState.status == 'Fail' || unstakeState.status == 'Exception')) {
      console.log(unstakeState.transaction)
      onWriteFail(unstakeState.errorMessage || "Unknown Error")
    }

  }, [walletTokenBalance, walletTokensResults, walletTokensNameResults, walletStakedTokensResults, approveState, stakeState, unstakeState])
    


  return (
    
    <div class="dapp">

      <div class={bannerOpen ? "open banner-container floating" : "closed banner-container floating"}>
        <div class="banner-padding">
          <div class="banner-close-button" role="button" tabIndex={0} aria-label="close" onKeyDown={() => setBannerOpen(false)} onClick={() => setBannerOpen(false)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg></div>
          <div class="banner-content">{bannerContent}</div>
        </div>
      </div>

      {!account && 
      <div class={(!account && !connectDelaying) ? "connect-container open" : "connect-container"}>
        <p class="connect-text">While using a browser-based wallet (ie: MetaMask), click "Connect Wallet" below to view your Moody Monstera!</p>
        <div class={account && "button disabled" || "button"} role="button" disabled={account} onClick={() => activateBrowserWallet(onWalletActivationError)} onKeyDown={() => activateBrowserWallet(onWalletActivationError)}>
          <div class="button-text">{(account && "Connected!") || "Connect Wallet"}</div>
        </div>
      </div>}

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="sill-title">Welcome to the<br/>Window Sill!</p>
        <p class="sill-text">Place your Moody Monsteras on the Window Sill to let them soak up sunshine, and emit <b>Good $VIBES!</b></p>
        <p class="sill-text">Each Monstera placed on the Window Sill generates 10 $VIBES per day! Claim $VIBES to add them to your wallet!</p>
        <p class="sill-text">$VIBES have no monetary value and are only meant to provide future utility within the Moody Monstera ecosystem.</p>
      </div>

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="wallet-text">Wallet Balance:</p>
        <p class="wallet-value">{(walletVibesBalance && utils.formatEther(walletVibesBalance, 0)) || "0"} $VIBES</p>
      </div>

      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="wallet-text">Claimable Balance:</p>
        <p class="wallet-value">{stakerInfoClaimable && utils.formatEther(stakerInfoClaimable) || "0"} $VIBES</p>
        <div class={claimState && claimState.status == "Mining" && "button st-button disabled" || "st-button button"} role="button" onClick={() => attemptToClaimVibes()} onKeyDown={() => attemptToClaimVibes()}>
          <div class="button-text">{(claimState && claimState.status == "Mining" && "Claiming...") || "Claim"}</div>
        </div>
      </div>

      {account && sillIsApproved == false &&
      <div class={account ? "did-connect-container open" : "did-connect-container"}>
        <p class="wallet-text">To begin staking, The Window Sill first needs approval to hold your Moody Monsteras.</p>
        <div class={approveState && approveState.status == "Mining" && "button st-button disabled" || "st-button button"} role="button" onClick={() => attemptToApproveForAll()} onKeyDown={() => attemptToApproveForAll()}>
          <div class="button-text">{(approveState && approveState.status == "Mining" && "Approving...") || "Approve"}</div>
        </div>
      </div>}

      {account && sillIsApproved == true &&
        <hr/>
      }

      {account && sillIsApproved == true &&
      <div class="st-category">
        <div class="st-category-text">Available</div>
      </div>}
      
      {account && sillIsApproved == true &&
        <div class="st-main-container">
          {walletTokensResults && walletTokensResults.length > 0 && walletTokensResults[0] !== undefined && walletTokensResults.map(tokenId => (
            <div class="st-container" key={utils.formatUnits(tokenId[0], 0)} id={'token-'+utils.formatUnits(tokenId[0], 0)}>
              <img class="st-image" src={mmImagesBaseUrl+"/"+utils.formatUnits(tokenId[0], 0)+".png"}/>

              <div class="did-connect-container open">
                <div class="mm-name st-name">Moody Monstera #{utils.formatUnits(tokenId[0], 0)}</div>
                {/* <div class="mm-custom-name st-custom-name">{tokenIdToNameMap[tokenId[0]] !== '' ? tokenIdToNameMap[tokenId[0]] : "Unnamed Monstera"}</div> */}
                <div class={stakeState && stakeState.status == "Mining" && "button st-button disabled" || "st-button button"} role="button" onClick={() => attemptToStake([tokenId])} onKeyDown={() => attemptToStake([tokenId])}>
                  <div class="button-text">{(stakingLoadingMap[utils.formatUnits(tokenId[0], 0)] && "Processing...") || "Stake"}</div>
                </div>
              </div>

            </div>
          ))}

          {walletTokensResults && walletTokensResults.length == 0 &&
            <div class="st-none">You Have No Moody Monsteras<br/>Available for Staking</div>
          }

          {walletTokensResults && walletTokensResults.length > 0 && walletTokensResults[0] !== undefined && 
          <div class={stakeState && stakeState.status == "Mining" && "button st-button all-stake disabled" || "st-button all-stake button"} role="button" onClick={() => attemptToStake(walletTokensResults)} onKeyDown={() => attemptToStake(walletTokensResults)}>
            <div class="button-text">{(Object.keys(stakingLoadingMap).length !== 0 && "Processing...") || "Stake All"}</div>
          </div>}

          <hr/>

        </div>}

        {account && sillIsApproved == true &&
        <div class="st-category st-staked">
          <div class="st-category-text">Staked</div>
        </div>}

        {account && sillIsApproved == true &&
        <div class="st-main-container">
          {allStakedTokenIds && allStakedTokenIds.length > 0 && allStakedTokenIds[0] !== undefined && allStakedTokenIds.map(tokenId => (
            <div class="st-container" key={utils.formatUnits(tokenId[0], 0)} id={'staked-token-'+utils.formatUnits(tokenId[0], 0)}>
              <img class="st-image" src={mmImagesBaseUrl+"/"+tokenId+".png"}/>

              <div class="did-connect-container open">
                <div class="mm-name st-name">Moody Monstera #{utils.formatUnits(tokenId[0], 0)}</div>
                {/* <div class="mm-custom-name st-custom-name">{tokenId !== '' ? tokenIdToNameMap[tokenId] : "Unnamed Monstera"}</div> */}
                <div class={unstakeState && unstakeState.status == "Mining" && "button st-button disabled" || "st-button button"} role="button" onClick={() => attemptToUnstake([tokenId])} onKeyDown={() => attemptToUnstake([tokenId])}>
                  <div class="button-text">{(stakingLoadingMap[utils.formatUnits(tokenId[0], 0)] && "Processing...") || "Unstake"}</div>
                </div>
              </div>

            </div>
          ))}

          {allStakedTokenIds && allStakedTokenIds.length == 0 &&
            <div class="st-none">You Have No Moody Monsteras<br/>on the Window Sill</div>
          }

          {allStakedTokenIds && allStakedTokenIds.length > 0 && allStakedTokenIds[0] !== undefined && 
          <div class={stakeState && stakeState.status == "Mining" && "button st-button all-stake disabled" || "st-button all-stake button"} role="button" onClick={() => attemptToUnstake(allStakedTokenIds)} onKeyDown={() => attemptToUnstake(allStakedTokenIds)}>
            <div class="button-text">{(Object.keys(stakingLoadingMap).length !== 0 && "Processing...") || "Unstake All"}</div>
          </div>}

        </div>}
      
    </div>
  )
}
